import React from "react";

export const Testimonials = () => {
  return (
    <>
      <div className="Testimonials">
        <h2>Testimonials</h2>
      </div>
    </>
  );
};
