import React from "react";

export const Gallery = () => {
  return (
    <>
      <div className="Gallery">
        <h2>Image Gallery</h2>
      </div>
    </>
  );
};
